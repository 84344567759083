import { createStore } from "vuex";
import axios from "axios";
import sites from "../sites.json";

let refDomain;
let refUrl = window.location.origin.match(/\/\/(.*?)\.com/);
if (refUrl && refUrl[1]) {
  refDomain = refUrl[1].replace(/\d+/g, "");
} else {
  refDomain = window.location.origin;
}

let domainId =
  sites.find((item) => item.domain === window.location.origin)?.id || null;

axios.defaults.headers.common["CF-Owner-Id"] = domainId;

const token = localStorage.getItem("access_token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

export default createStore({
  state: {
    data: [],
    user: [],
    error_message: "",
    status: "",
    user_requires_2fa: false,
    requires_email_2fa: false,
    isLoggedIn: !!localStorage.getItem("access_token"),
    token: token || "",
    dashboard_data: [],
    users: [],
    details: [],
    check_2fa_status_google: "",
    enable_2fa_status_google: "",
    disable_2fa_status_google: "",
    check_2fa_email_status: "",
    enable_2fa_email_status: "",
    disable_2fa_email_status: "",
    promo_codes: [],
    promo_code_message: "",
  },
  getters: {
    user: (state) => !!state.data,
    isLoggedIn: (state) => state.isLoggedIn,
    userData: (state) => state.user,
  },
  mutations: {
    set_error_message(state, response) {
      const errorMap = {
        401: () => {
          localStorage.removeItem("access_token");
          localStorage.removeItem("profile");
          localStorage.removeItem("loginTime");
          window.location.href = "/login";
        },
        404: "data_not_found",
        400: (response) =>
          response.data.success === false
            ? response.data.data.message
            : Object.values(response.data)[0][0],
        403: "forbidden",
        405: "method_not_allowed",
        429: "too_many_requests",
        422: (response) =>
          response.data.success === false
            ? response.data.data.message
            : Object.values(response.data)[0][0],
        500: "please_try_again",
      };

      state.error_message =
        errorMap[response.status] instanceof Function
          ? errorMap[response.status](response)
          : errorMap[response.status] || "unknown_error";
    },

    requires_2fa(state, data) {
      state.user_requires_2fa = data;
    },
    requires_email_2fa(state, data) {
      state.requires_email_2fa = data;
    },

    islogged(state, data) {
      state.isLoggedIn = data;
    },
    auth_request(state, data) {
      state.isLoggedIn = false;
      state.data = data;
      state.status = "pending";
    },

    auth_success(state, { token, user }) {
      state.isLoggedIn = true;
      state.data = user;
      state.user = user;
      state.status = "success";
      state.token = token;
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },

    auth_error(state, data) {
      state.isLoggedIn = false;
      state.data = data;
      state.user = [];
      state.status = "error";
    },

    logout(state) {
      state.isLoggedIn = false;
      state.token = "";
      state.data = [];
      state.user = [];
      state.status = "error";
      delete axios.defaults.headers.common["Authorization"];
    },

    update_password(state, data) {
      state.message = data;
      state.status = "success";
    },

    set_data(state, data) {
      state.data = data;
    },

    set_users(state, users) {
      state.users = users;
    },

    set_user_details(state, details) {
      state.details = details;
    },

    set_dashboard(state, data) {
      state.data = data;
    },

    set_promo_codes(state, data) {
      state.promo_codes = data;
    },
    commit_add_promo_codes(state, data) {
      state.promo_code_message = data;
    },

    check_2fa_status_google(state, data) {
      state.check_2fa_status_google = data;
    },
    enable_2fa_status_google(state, data) {
      state.enable_2fa_status_google = data;
    },
    disable_2fa_status_google(state, data) {
      state.disable_2fa_status_google = data;
    },

    check_2fa_email_status(state, data) {
      state.check_2fa_email_status = data;
    },
    enable_2fa_email_status(state, data) {
      state.enable_2fa_email_status = data;
    },
    disable_2fa_email_status(state, data) {
      state.disable_2fa_email_status = data;
    },

    data_error(state, response) {
      const errorMap = {
        404: "data_not_found",
        400: (response) =>
          response.data.success === false
            ? response.data.data.message
            : Object.values(response.data)[0][0],
        401: () => {
          localStorage.removeItem("access_token");
          localStorage.removeItem("profile");
          localStorage.removeItem("loginTime");
          window.location.href = "/login";
        },
        403: "forbidden",
        405: "method_not_allowed",
        429: "too_many_requests",
        422: (response) =>
          response.data.success === false
            ? response.data.data.message
            : Object.values(response.data)[0],
        500: "please_try_again",
      };
      state.error_message =
        errorMap[response.status] instanceof Function
          ? errorMap[response.status](response)
          : errorMap[response.status] || "unknown_error";
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request", user);
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/login`,
          data: user,
          method: "POST",
        })
          .then((resp) => {
            const token = resp.data.data.token;
            const user = resp.data.data.user;

            localStorage.setItem("access_token", token);
            localStorage.setItem("profile", JSON.stringify(user));
            localStorage.setItem("loginTime", Date.now().toString());

            commit("auth_success", { token, user });
            resolve(resp);
          })
          .catch((err) => {
            if (err.response.data?.requires_2fa) {
              commit("requires_2fa", true);
            }
            if (err.response.data?.requires_email_2fa) {
              commit("requires_email_2fa", true);
            }

            commit("islogged", false);
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    get_all_users({ commit }, queryString) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/get-all-users?${queryString}`,
          method: "GET",
        })
          .then((resp) => {
            const users = resp.data;
            commit("set_users", users);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    get_user_details({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/user-detail/${id}`,
          method: "GET",
        })
          .then((resp) => {
            const userDetails = resp.data;
            commit("set_user_details", userDetails);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    get_dashboard({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/dashboard`,
          method: "GET",
        })
          .then((resp) => {
            const data = resp.data;
            commit("set_dashboard", data);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    get_promo_codes({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/promo-codes`,
          method: "GET",
        })
          .then((resp) => {
            const data = resp.data.data;
            commit("set_promo_codes", data);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    add_promo_codes({ commit }, promo_code) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/add-promo-code`,
          method: "POST",
          data: promo_code,
        })
          .then((resp) => {
            const data = resp.data;
            commit("commit_add_promo_codes", data);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve) => {
        localStorage.removeItem("access_token");
        localStorage.removeItem("profile");
        localStorage.removeItem("loginTime");
        commit("logout");
        resolve();
      });
    },

    change_password({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/change-password`,
          data: data,
          method: "PUT",
        })
          .then((resp) => {
            commit("update_password", resp.data.message);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    check2fa_google({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/check-google-2fa`,
          method: "GET",
        })
          .then((resp) => {
            commit("check_2fa_status_google", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },
    enable2fa_google({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/enable-google-2fa`,
          data: data,
          method: "PUT",
        })
          .then((resp) => {
            commit("enable_2fa_status_google", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },
    disable2fa_google({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/disable-google-2fa`,
          data: data,
          method: "PUT",
        })
          .then((resp) => {
            commit("disable_2fa_status_google", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    check2faEmail({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/check-email-2fa`,
          method: "GET",
        })
          .then((resp) => {
            commit("check_2fa_email_status", resp.data);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    enable2faEmail({ commit }, password) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/enable-email-2fa`,
          method: "PUT",
          data: password,
        })
          .then((resp) => {
            commit("set_data_message", resp.data.message);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },

    disable2faEmail({ commit }, password) {
      return new Promise((resolve, reject) => {
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/disable-email-2fa`,
          data: password,
          method: "PUT",
        })
          .then((resp) => {
            commit("set_data_message", resp.data.message);
            resolve(resp);
          })
          .catch((err) => {
            commit("data_error", err.response);
            reject(err);
          });
      });
    },
  },
  modules: {},
});
