import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import UsersList from "../views/UsersList.vue";
import UserDetail from "../views/UserDetails.vue";
import Settings from "../views/Settings.vue";
import PromoCodes from "@/views/PromoCodes.vue";

const routes = [
  // {
  //   path: "/",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/",
    name: "Users-List",
    component: UsersList,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/users-list",
    name: "UsersList",
    component: UsersList,
    meta: { requiresAuth: true },
  },
  {
    path: "/promo-codes",
    name: "PromoCodes",
    component: PromoCodes,
    meta: { requiresAuth: true },
  },
  // {
  //   path: "/user-details/:id",
  //   name: "UserDetail",
  //   component: UserDetail,
  //   props: true,
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.isLoggedIn;

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
