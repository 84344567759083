<template>
    <section class="change-password-section">
        <h1 class="change-password-heading">Change Password</h1>
        <p v-if="successMessage">{{ successMessage }}</p>
        <p v-if="errorMessage">{{ errorMessage }}</p>
        <form class="change-password-form" @submit.prevent="handleChangePassword">
            <label class="change-password-label">Enter Your Current Password</label>
            <input type="password" placeholder="Current Password" v-model="currentPassword" required />
            <label class="change-password-label">Choose Your New Password</label>
            <input type="password" placeholder="New Password" v-model="newPassword" required />
            <label class="change-password-label">Repeat New Password</label>
            <input type="password" placeholder="Repeat Password" v-model="repeatPassword" required />
            <button class="password-change-btn" type="submit">Change Password</button>
        </form>
    </section>
</template>

<script>
export default {
    name: "Settings",
    data() {
        return {
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
            errorMessage: '',
            successMessage: ''
        };
    },
    methods: {
        handleChangePassword() {
            if (this.newPassword !== this.repeatPassword) {
                this.errorMessage = "Passwords do not match!"
                return;
            }

            const data = {
                current_password: this.currentPassword,
                new_password: this.newPassword,
                new_password_confirmation: this.repeatPassword
            };

            this.$store
                .dispatch("change_password", data)
                .then((response) => {
                    this.successMessage = "Password Changed Successfully!";
                    this.currentPassword = "";
                    this.newPassword = "";
                    this.repeatPassword = "";
                })
                .catch((error) => {
                    this.errorMessage = this.$store.state.error_message
                });
        },
    },
};
</script>
<style scoped>
.change-password-section {
    display: flex;
    flex-direction: column;
    padding: 48px;
}

.change-password-heading {
    text-align: center;
    font-size: 36px;
    padding: 36px;
}

.change-password-form {
    padding: 48px;
    background-color: var(--background-accent);
    border-radius: 10px;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
    display: flex;
    flex-direction: column;
}

/* sdasd */
input,
label {
    margin: 12px;
}

.password-change-btn {
    align-self: center;
    border-radius: 10px;
    border: 1px solid var(--primary);
    padding: 8px 20px;
    margin-top: 24px;
    background-color: var(--primary);
    color: var(--background-accent);
    font-weight: 600;
    text-transform: uppercase;
}

.password-change-btn:hover {
    background-color: transparent;
    color: var(--primary);
}

@media only screen and (max-width: 680px) {
    form {
        padding: 24px !important;
    }
}

@media only screen and (max-width: 430px) {
    h1 {
        font-size: 14px !important;
    }

    labe,
    input,
    button {
        font-size: 12px !important;
        margin: 0;
    }
}
</style>