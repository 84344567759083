<template>
    <section class="users-list-section section">
        <h1 class="users-list-heading">Users List</h1>
        <div class="date-filters">
            <div class="date-filter">
                <label>Promo Code</label>
                <select class="promo-code-filter" v-model="promo_code" name="" id="">
                    <option class="promo-code-option" value="0">All</option>
                    <option class="promo-code-option" v-for="(k, i) in promoCodes" :value="k.promo_code">{{ k.promo_code
                        }}
                    </option>
                </select>
            </div>
            <div class="date-filter">
                <label>Start Date</label>
                <flat-pickr :config="configFromDateTimePicker" v-model="fromDate" />
            </div>
            <div class="date-filter">
                <label>End Date</label>
                <flat-pickr :config="configToDateTimePicker" v-model="toDate" />
            </div>
            <div class="date-filter">
                <label style="color: var(--background-color);">search</label>
                <button class="date-filter-submit" @click="getData">Filter</button>
            </div>
        </div>
        <div class="table-container-div">
            <table class="users-list-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Username</th>
                        <th>Name Lastname</th>
                        <th>Last login</th>
                        <th>Total bet</th>
                        <th>Total win</th>
                        <th>Profit</th>
                        <th>Percent</th>
                        <th>Created at</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user in users" :key="user.id">
                        <td>{{ user.id }}</td>
                        <td>{{ user.username }}</td>
                        <td>{{ user.name }} {{ user.lastname }}</td>
                        <td>{{ user.last_login }}</td>
                        <td>{{ user.total_bet }}</td>
                        <td>{{ user.total_win }}</td>
                        <td>{{ user.profit }}</td>
                        <td>{{ user.percent.toFixed(8) }}</td>
                        <td>{{ user.created_at }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <h3 class="earned">Earned Money: {{ earnedMoney }}</h3>
        <h3 class="total">Total Users: {{ users.length }}</h3>
    </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
    name: "UsersList",
    components: {
        flatPickr,
    },
    data() {
        return {
            promo_code: "0",
            configFromDateTimePicker: {
                enableTime: true,
                time_24hr: true,
                dateFormat: "Y-m-d H:i:S",
            },
            configToDateTimePicker: {
                enableTime: true,
                time_24hr: true,
                dateFormat: "Y-m-d H:i:S",
            },
            fromDate: "",
            toDate: "",
        };
    },
    computed: {
        ...mapState({
            users: (state) => state.users,
            promoCodes: (state) => state.promo_codes,
        }),
        earnedMoney() {
            return this.users.reduce((total, user) => total + user.percent, 0).toFixed(8);
        },
    },
    methods: {
        ...mapActions({
            fetchUsers: "get_all_users",
            get_promo_codes: "get_promo_codes"
        },),
        getData() {
            const queryParams = {
                from_date: this.fromDate,
                to_date: this.toDate,
                promo_code: this.promo_code
            };
            const queryString = Object.entries(queryParams)
                .filter(([key, value]) => value)
                .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                .join("&");

            console.log("Query String:", queryString); // Log the query string

            this.fetchUsers(queryString)
                .then(() => {
                    console.log("Users fetched successfully:", this.users); // Log the updated users
                })
                .catch((error) => {
                    console.error("Error fetching users:", error);
                });
        },
        getFormattedDate(offsetDays = 0, time = "00:00:00") {
            const date = new Date();
            date.setDate(date.getDate() + offsetDays);
            const yyyy = date.getFullYear();
            const mm = String(date.getMonth() + 1).padStart(2, "0");
            const dd = String(date.getDate()).padStart(2, "0");
            return `${yyyy}-${mm}-${dd} ${time}`;
        },
    },
    mounted() {
        this.fromDate = this.getFormattedDate(0, "00:00:00");
        this.toDate = this.getFormattedDate(0, "23:59:59");

        this.getData();
    },
    created() {
        this.get_promo_codes();
    }
};
</script>

<style scoped>
.earned {
    padding: 48px;
}

.users-list-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.users-list-heading {
    font-size: 48px;
    padding: 48px;
}

.date-filters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
}

.date-filter {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.date-filter-submit {
    border-radius: 10px;
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--background-color);
    font-weight: 600;
    text-transform: uppercase;
    padding: 12px 24px;
}

.table-conteiner {
    margin: 36px;
    background-color: var(--background-accent);
    border-radius: 10px;
    padding: 36px 64px;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
}

.users-list-table {
    width: 100%;
}

.users-list-table thead tr th {
    padding: 16px;
    background-color: var(--primary);
    color: var(--background-accent);
    font-weight: 600;
    font-size: 18px;
    text-transform: uppercase;
}

.users-list-table tbody tr td {
    padding: 12px;
    background-color: var(--background-accent);
}

.details-btn {
    color: var(--primary);
    text-transform: uppercase;
    background-color: transparent;
}

.promo-code-filter {
    border: var(--primary) 1px solid;
    border-radius: 10px;
    padding: 8px 24px;
    background-color: transparent;
}

.promo-code-option {
    color: #000;
}

@media only screen and (max-width: 1200px) {
    .users-list-table thead tr th {
        font-size: 12px;
    }
}

@media only screen and (max-width: 880px) {
    .users-list-table {
        border-collapse: collapse;
        width: 100%;
    }

    .table-container-div {
        width: 100vw;
        overflow-x: scroll;
    }
}

@media only screen and (max-width: 725px) {
    .date-filters {
        display: grid;
        grid-template-columns: 1fr;
    }
}
</style>