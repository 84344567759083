<template>
    <section class="login-section section">
        <div class="login-container">
            <div class="logo-container">
        <img src="/assets/logo.png" alt="affiliate Logo">
    </div>
            <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
            <form class="login-form" @submit.prevent="handleLogin" autocomplete="off">
                <div class="input-container" v-if="!requires_email_2fa && !user_requires_2fa">
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <input type="text" v-model="username" class="login-input" placeholder="Username" autocomplete="off">
                </div>
                <div class="input-container" v-if="!requires_email_2fa && !user_requires_2fa">
                    <i class="fa fa-lock"></i>
                    <input type="password" v-model="password" class="login-input" placeholder="Password"
                        autocomplete="off">
                </div>
                <div v-if="user_requires_2fa" class="psw-input-container">
                    <input type="text" class="form-control" placeholder="Google 2FA Code" v-model="google_code"
                        required>
                </div>
                <div v-if="requires_email_2fa" class="psw-input-container">
                    <input type="text" class="form-control" placeholder="Email 2FA Code" v-model="email_code" required>
                </div> 
                <button type="submit" class="login-btn">Login</button>
                <a href="https://cryptonbets.com/pages?data=forgot-affiliate-account" target="_blank">Forgot
                    Account?</a>
                <a href="https://cryptonbets.com/pages?data=become-affiliate" target="_blank">Do you want to become
                    affiliator?</a>
            </form>
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "Login",
    data() {
        return {
            username: "",
            password: "",
            errorMessage: '',
            user_requires_2fa: false,
            requires_email_2fa: false,
            google_code: '',
            email_code: ''
        };
    },
    methods: {
        ...mapActions(["login"]),
        async handleLogin() {
            try {
                const user = {
                    username: this.username, password: this.password, google_code: this.google_code,
                    email_code: this.email_code
                };
                await this.login(user);
                this.$router.push("/");
            } catch (e) {
                if (this.$store.state?.user_requires_2fa) {
                    this.user_requires_2fa = true
                }
                if (this.$store.state?.requires_email_2fa) {
                    this.requires_email_2fa = true
                }
                this.errorMessage = this.$store.state.error_message
            }
        },
    },
};
</script>

<style scoped>
.login-section {
    width: 100vw;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-container {
    width: 600px;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 48px;
    box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.4);
}

.login-heading {
    margin-top: 64px;
    text-transform: uppercase;
    font-weight: 600;
}

.login-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.input-container {
    display: flex;
    align-items: center;
    border: 1px solid var(--primary);
    border-radius: 10px;
    padding: 0 16px;
}

.login-input {
    border: none;
}

.login-btn {
    border-radius: 10px;
    padding: 12px 24px;
    background-color: var(--primary);
    font-weight: 600;
    color: var(--background-accent);
    text-transform: uppercase;
    font-size: 16px;
    border: 1px solid var(--primary);
}

.login-btn:hover {
    background-color: transparent;
    color: var(--primary);
}

.error-message {
    color: #da3d3d;
    border: 3px solid #da3d3d;
    padding: 8px;
}

@media only screen and (max-width: 650px) {
    .login-container {
        width: 500px;
        height: 350px;
    }

    .login-heading {
        margin-top: 24px;
    }
}

@media only screen and (max-width: 520px) {
    .login-container {
        width: 350px;
        height: 450px;
    }
}

.logo-container {
        text-align: center;
    }

    .logo-container img {
        max-width: 25%;
    }

</style>
<!-- Comment -->