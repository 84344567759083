<template>
    <transition name="fade">
        <div v-if="visible" :class="['popup', typeOfAlert, typeOfClass]">
            <span class="message">{{ message }}</span>
            <i class="fa fa-close close-icon" @click="$emit('close')"></i>
        </div>
    </transition>
</template>

<script>
export default {
    name: "ErrorPopup",
    props: {
        message: {
            type: String,
            default: "Error Message",
        },
        typeOfAlert: {
            type: String,
            required: true,
            validator(value) {
                return ["success", "warning", "error"].includes(value);
            },
        },
        typeOfClass: {
            type: String,
            default: 'middle',
            validator(value) {
                return ["left", "middle", "right"].includes(value)
            }
        }
    },
    data() {
        return {
            visible: true,
        };
    },
    methods: {
    },
};
</script>

<!-- // გამოძახება სხვა კომპონენტში -->
<!-- <template>
  <div>
    <button @click="showError">Show Error</button>
    <ErrorPopup v-if="showPopup" :message="'Something went wrong!'" />
  </div>
</template>

<script>
import ErrorPopup from "./ErrorPopup.vue";

export default {
  components: { ErrorPopup },
  data() {
    return {
      showPopup: false,
    };
  },
  methods: {
    showError() {
      this.showPopup = true;
      setTimeout(() => {
        this.showPopup = false;
      }, 3000); // Automatically hide after 3 seconds
    },
  },
};
</script>  -->

<style scoped>
.popup {
    position: fixed;
    bottom: 20px;
    backdrop-filter: blur(5px);
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    min-width: 300px;
    min-height: 70px;
    z-index: 9999;
}

.popup.left {
    right: 83%;
}

.popup.middle {
    right: 50%;
    transform: translateX(50%);
}

.popup.right {
    right: 2%;
}

.popup.success {
    border: 2px solid #0BDA51;
    background-color: #0bda5071;
}

.popup.warning {
    border: 2px solid var(--primary);
    background-color: #ffd5007c;
}

.popup.error {
    border: 2px solid #E23D28;
    background-color: #e23e287e;
}

.popup span {
    color: #fff;
    font-weight: 600;
}

.popup i {
    position: absolute;
    top: 4px;
    right: 4px;
    color: #fff;
}

.message {
    margin-right: 10px;
}

.close-icon {
    cursor: pointer;
    font-size: 18px;
    margin-left: 10px;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>