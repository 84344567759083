<template>
    <section class="section promo-code-section">
        <h1 class="promo-codes-heading">Promo Codes</h1>

        <div class="promo-codes-container">
            <div v-if="promoCodes && promoCodes.length">
                <div class="pc-header">
                    <span>Promo Code:</span>
                    <span>Promo Code Created At: </span>
                    <span>URL: </span>
                </div>
                <div v-for="(code, index) in promoCodes" :key="index" class="promo-code">
                    <span @click="copyToClipboard(code.promo_code)" style="cursor: pointer;">
                        <i class="fa fa-copy" style="margin-right: 4px; color: var(--primary);"></i> {{ code.promo_code
                        }}
                    </span>
                    <p> {{ code.created_at.replace('T', ' ').replace(/\.\d+Z$/, '') }}</p>
                    <div class="url-btns">
                        <button @click="copyToClipboard(base_url + code.promo_code)">Copy URL</button>
                        <button @click="copyToClipboard(base_url_1 + code.promo_code)">Copy with mobile</button>
                        <button @click="copyToClipboard(base_url_2 + code.promo_code)">Copy with email</button>
                    </div>
                </div>
            </div>
            <div v-else>
                <ErrorPopup type-of-alert="warning" type-of-class="middle" message="Loading Promo Codes" />
            </div>

            <div class="add-promo-code">
                <input v-model="newPromoCode" type="text" placeholder="Enter new promo code" class="promo-input" />
                <button @click="addPromoCode">Add Promo Code</button>
            </div>
            <ErrorPopup v-if="errorMessage" type-of-alert="error" type-of-class="middle" :message="errorMessage"
                @close="errorMessage = ''" />
            <ErrorPopup v-if="successMessage" type-of-alert="success" type-of-class="middle" :message="successMessage"
                @close="successMessage = ''" />
        </div>
    </section>
</template>
<script>
import ErrorPopup from "@/components/ErrorPopup.vue";
import { mapActions, mapState } from "vuex";

export default {
    name: "PromoCodes",
    data() {
        return {
            newPromoCode: "",
            errorMessage: "",
            successMessage: "",
            base_url: "https://cryptonbets.com/signup?promo_code=",
            base_url_1: "https://cryptonbets.com/signup?tab=fast&method=mobile&promo_code=",
            base_url_2: "https://cryptonbets.com/signup?tab=fast&method=email&promo_code="
        };
    },
    components: {
        ErrorPopup
    },
    computed: {
        ...mapState({
            promoCodes: (state) => state.promo_codes,
        }),
    },
    methods: {
        ...mapActions(["get_promo_codes", "add_promo_codes"]),
        copyToClipboard(code) {
            navigator.clipboard.writeText(code).then(() => {
                this.successMessage = "Text copied successfully"
            });
        },
        addPromoCode() {
            if (this.newPromoCode.trim() === "") {
                return;
            }
            this.add_promo_codes({ promo_code: this.newPromoCode })
                .then((response) => {
                    this.newPromoCode = "";
                    this.get_promo_codes();
                    this.successMessage = response.data.message
                })
                .catch((err) => {
                    this.errorMessage = err.response.data.message
                });
        },
    },
    created() {
        this.get_promo_codes();
    },
};
</script>

<style scoped>
.promo-codes-section {
    display: flex;
    flex-direction: column;
    padding: 48px;
}

.promo-codes-heading {
    text-align: center;
    font-size: 36px;
    padding: 36px;
}

.promo-codes-container {
    background-color: var(--background-accent);
    border-radius: 10px;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
    max-width: 135vh;
    margin: 0 auto;
    padding: 24px;
}


.promo-code,
.pc-header {
    display: grid;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    grid-template-columns: 20fr 25fr 55fr;
}

.url-btns {
    display: flex;
    flex-wrap: wrap;
}

.add-promo-code {
    margin-top: 20px;
    display: flex;
    align-items: center;
}

.promo-input {
    padding: 10px 4px;
    margin: 12px;
    width: 75%;

}

button {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: var(--background-color);
    text-transform: uppercase;
    border-radius: 10px;
    padding: 8px 24px;
    font-weight: 600;
    margin-left: 4px;
}

button:hover {
    background-color: transparent;
    color: #fff;
}

span {
    display: flex;
    align-items: center;
    font-weight: 700;
}

span p {
    margin-right: 8px;
    margin-left: 8px;
    font-weight: 400;
}

@media only screen and (max-width: 880px) {
    .promo-codes-container {
        padding: 8px;
    }

    .promo-code {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        padding: 4px;
        border-bottom: 2px solid var(--primary);
    }
}
</style>