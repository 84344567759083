<template>
    <section class="google-section">
        <h1 class="google-heading">2FA Google</h1>
        <div class="google-container">
            <h3 class="google-status">{{ twofaStatus === 'enabled' ? '2FA is Enabled' : '2FA is Disabled' }}</h3>

            <form v-if="twofaStatus === 'disabled'" @submit.prevent="enable2FA">
                <input type="password" v-model="password" placeholder="Enter Your Password" required />
                <button type="submit" class="google-btn">Enable 2FA</button>
            </form>

            <div v-if="twofaStatus === 'enabled'">
                <p>Scan this QR code with your authenticator app:</p>
                <div v-html="qrCodeUrl"></div>
                <p>Or use this secret key:</p>
                <p>{{ secretKey }}</p>

                <form @submit.prevent="disable2FA">
                    <input type="password" v-model="password" placeholder="Enter Your Password" required />
                    <button type="submit" class="google-btn">Disable 2FA</button>
                </form>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        return {
            password: "",
            twofaStatus: '',
            qrCodeUrl: "",
            secretKey: "",
        };
    },
    computed: {
        ...mapState(['twofaStatusGoogle']),
    },
    methods: {
        enable2FA() {
            const data = { password: this.password };
            this.$store.dispatch('enable2fa_google', data)
                .then(response => {
                    this.twofaStatus = 'enabled';
                    this.qrCodeUrl = response.data.qrCodeUrl;
                    this.secretKey = response.data.secret;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        disable2FA() {
            const data = { password: this.password };
            this.$store.dispatch('disable2fa_google', data)
                .then(response => {
                    this.twofaStatus = 'disabled';
                })
                .catch(error => {
                    console.error(error);
                });
        },
        fetch2FAStatus() {
            this.$store.dispatch('check2fa_google')
                .then(response => {
                    this.twofaStatus = response.data.message;
                    this.qrCodeUrl = response.data.qrCodeUrl;
                    this.secretKey = response.data.secret;
                })
                .catch(error => {
                    console.error(error);
                });
        }
    },
    mounted() {
        this.fetch2FAStatus();

    }
};
</script>

<style scoped>
.google-section {
    display: flex;
    flex-direction: column;
    padding: 48px;

}

.google-heading {
    text-align: center;
    font-size: 36px;
    padding: 36px;
}

.google-container {
    background-color: var(--background-accent);
    border-radius: 10px;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    width: 100%;
    padding: 36px;
}

.google-status {
    font-size: 1.2em;
    font-weight: bold;
}

form {
    margin-top: 10px;
}

.google-btn {
    align-self: center;
    border-radius: 10px;
    border: 1px solid var(--primary);
    padding: 8px 20px;
    margin-top: 8px;
    background-color: var(--primary);
    color: var(--background-accent);
    font-weight: 600;
    text-transform: uppercase;
}

.google-btn:hover {
    background-color: transparent;
    color: var(--primary);
}
</style>