<template>
    <section class="email-section">
        <h1 class="email-heading">2FA Email</h1>
        <div class="email-container">
            <h3 class="email-status">
                {{ twofaStatus === 'enabled' ? '2FA is Enabled' : '2FA is Disabled' }}
            </h3>
            <!-- If 2FA is disabled, show the "Enable" button -->
            <input v-model="password" type="password" placeholder="Enter your password" />
            <button v-if="twofaStatus === 'disabled'" @click="enable2FA" class="email-btn">Enable</button>

            <!-- If 2FA is enabled, show the password input and "Disable" button -->
            <div v-else>
                <button @click="disable2FA" class="email-btn">Disable</button>
            </div>
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            password: "",
            twofaStatus: "",
        };
    },
    computed: {
        ...mapState(["twofaStatusEmail"]),
    },
    methods: {
        enable2FA() {
            const data = { password: this.password };
            this.$store
                .dispatch("enable2faEmail", data)
                .then(() => {
                    this.twofaStatus = "enabled";
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        disable2FA() {
            const data = { password: this.password };
            this.$store
                .dispatch("disable2faEmail", data)
                .then(() => {
                    this.twofaStatus = "disabled";
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        fetch2FAStatus() {
            this.$store
                .dispatch("check2faEmail")
                .then((response) => {
                    this.twofaStatus = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    mounted() {
        this.fetch2FAStatus();
    },
};
</script>
<style scoped>
.email-section {
    display: flex;
    flex-direction: column;
    padding: 48px;

}

.email-heading {
    text-align: center;
    font-size: 36px;
    padding: 36px;
}

.email-container {
    background-color: var(--background-accent);
    border-radius: 10px;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
    margin: 0 auto;
    width: 100%;
    padding: 36px;
}

.email-status {
    font-size: 1.2em;
    font-weight: bold;
}

form {
    margin-top: 10px;
}

.email-btn {
    align-self: center;
    border-radius: 10px;
    border: 1px solid var(--primary);
    padding: 8px 20px;
    margin-top: 8px;
    background-color: var(--primary);
    color: var(--background-accent);
    font-weight: 600;
    text-transform: uppercase;
}

.email-btn:hover {
    background-color: transparent;
    color: var(--primary);
}
</style>