<template>
    <div class="settings-container">
        <div class="tabs">
            <button v-for="(tab, index) in tabs" :key="index" :class="{ 'active': activeTab === tab }"
                @click="changeTab(tab)">
                {{ tab }}
            </button>
        </div>

        <ChangePassword v-if="activeTab === 'Change Password'" />
        <Google2FA v-if="activeTab === 'Google 2FA'" />
        <Email2FA v-if="activeTab === 'Email 2FA'" />
    </div>
</template>

<script>
import ChangePassword from '@/components/ChangePassword.vue';
import Google2FA from '@/components/Google2FA.vue';
import Email2FA from '@/components/Email2FA.vue';

export default {
    name: "Settings",
    components: {
        ChangePassword, Google2FA, Email2FA
    },
    data() {
        return {
            activeTab: 'Change Password',  // default active tab
            tabs: ['Change Password', 'Google 2FA', 'Email 2FA']  // available tabs
        };
    },
    methods: {
        changeTab(tab) {
            this.activeTab = tab;
        }
    }
};
</script>
<style scoped>
.settings-container {
    margin-top: 70px;
}

.tabs {
    display: flex;
    background-color: var(--background-accent);
    width: 30vw;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.4);
    gap: 10px;
    margin-top: 96px;
}

.tabs button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 10px;
    background-color: transparent;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
}

.tabs button.active,
.tabs button:hover {
    background-color: var(--background-color);
}

@media only screen and (max-width: 1200px) {
    .tabs {
        width: 50vw;
    }
}

@media only screen and (max-width: 680px) {
    .tabs {
        width: 80vw;
    }
}

@media only screen and (max-width: 430px) {
    .tabs button {
        padding: 4px 12px;
    }
}
</style>