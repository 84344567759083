<template>
    <header>
            <div class="header-logo-container">
        </div>
        <nav v-if="getMonitorSize() === 'web'" class="header-nav">
            <!-- <router-link v-if="this.$store.state.isLoggedIn" to="/" class="nav-link">Dashboard</router-link> -->
            <router-link v-if="this.$store.state.isLoggedIn" to="/promo-codes" class="nav-link">Promo
                Codes</router-link>
            <router-link v-if="this.$store.state.isLoggedIn" to="/users-list" class="nav-link">Users List</router-link>
            <router-link v-if="this.$store.state.isLoggedIn" to="/settings" class="nav-link">Settings</router-link>
            <button v-if="this.$store.state.isLoggedIn" @click="openLogoutModal"
                class="nav-link login-btn">Logout</button>
        </nav>

        <div v-else class="hamburger-menu">
            <button class="hamburger-icon" @click="toggleMenu">
                <i v-if="!isMenuOpen" class="fa fa-bars" aria-hidden="true"></i>
                <i v-else class="fa fa-times" aria-hidden="true"></i>
            </button>
            <div v-if="isMenuOpen" class="dropdown-menu">
                <!-- <router-link to="/" class="dropdown-link" @click="closeMenu">Dashboard</router-link> -->
                <router-link to="/promo-codes" class="dropdown-link" @click="closeMenu">Promo Codes</router-link>
                <router-link to="/users-list" class="dropdown-link" @click="closeMenu">Users List</router-link>
                <router-link to="/settings" class="dropdown-link" @click="closeMenu">Settings</router-link>
                <router-link to="/login" class="dropdown-link login-btn" @click="closeMenu">Logout</router-link>
            </div>
        </div>

        <div v-if="modal" class="logout-modal-container">
            <div class="logout-modal">
                <span>Are you sure you want to sign out?</span>
                <div class="logout-modal-actions">
                    <button @click="confirmLogout" class="btn btn-success">Yes</button>
                    <button @click="closeModal" class="btn btn-secondary">No</button>
                </div>
                <i class="close-modal fa fa-times" @click="closeModal" style="cursor: pointer;"></i>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    data() {
        return {
            isMenuOpen: false,
            modal: false
        };
    },
    methods: {
        toggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
        closeMenu() {
            this.isMenuOpen = false;
        },

        confirmLogout() {
            this.$store.dispatch('logout').then(() => {
                this.$router.push('/login')
            })
            this.modal = false;
            this.data = [];
        },
        openLogoutModal() {
            this.modal = true;
        },
        closeModal() {
            this.modal = false;
        },
    },
};
</script>
<style scoped>
header {
    background-color: var(--background-accent);
    padding: 12px 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
}

.header-logo-container {
    max-width: 180px;
}

.header-logo {
    width: 100%;
}

.header-nav {
    display: flex;
    align-items: center;
    gap: 24px;
}

.nav-link {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    border-radius: 10px;
    padding: 12px 20px;
}

.nav-link:hover {
    background-color: var(--background-color);
}

.login-btn {
    background-color: var(--primary);
    color: var(--background-accent);
    font-weight: 600;
    border: 1px solid var(--primary);
}

.login-btn:hover {
    color: var(--primary);
    background-color: var(--background-accent);
}

/**********************/
/* HAMBURGER MENU */
/**********************/
.hamburger-menu {
    position: relative;
}

.hamburger-icon {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: var(--primary);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--background-accent);
    box-shadow: 0 0px 10px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
}

.dropdown-link {
    padding: 12px 20px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color);
    text-decoration: none;
}

.dropdown-link:hover {
    background-color: var(--background-color);
}

.login-btn:hover {
    background-color: var(--background-accent);
}

.logout-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.logout-modal {
    background: var(--background-accent);
    padding: 64px 16px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
    position: relative;
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.logout-modal span {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    padding: 0;
    margin: 0;
    background-color: transparent;
    height: auto;
    transform: translateY(0);
    text-align: center;
}

.logout-modal-actions {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.logout-modal-actions button {
    padding: 8px 16px;
    font-size: 14px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.logout-modal-actions .btn-success {
    background-color: var(--primary);
    color: var(--background-accent);
    font-weight: 600;
    border: 1px solid var(--primary);
    text-transform: uppercase;
}

.logout-modal-actions .btn-success:hover {
    background-color: transparent;
    color: var(--primary);
}

.logout-modal-actions .btn-secondary {
    background-color: transparent;
    color: var(--primary);
    border: 1px solid var(--primary);
    text-transform: uppercase;
    font-weight: 600;
}

.logout-modal-actions .btn-secondary:hover {
    background-color: var(--primary);
    color: var(--background-accent);
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
}


@media only screen and (max-width: 650px) {
    header {
        padding: 12px;
    }

    .dropdown-link {
        font-size: 12px;
    }
}
</style>